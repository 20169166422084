<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card
      class="product-edit-wrapper"
      :title="`Region: ${productEdit.region_id ? productEdit.region_id.toUpperCase() : ''}`"
    >
      <validation-observer ref="editProductValidation">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <!-- Title Previous -->
            <b-col md="3">
              <b-form-group
                label="Title"
                label-for="product-edit-title"
                class="mb-2"
              >
                <b-form-input
                  id="product-edit-title"
                  v-model="productEdit.title"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- Title Edit -->
            <b-col md="3">
              <b-form-group
                label-for="product-edit-title"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-title"
                    v-model="productOnEdit.title"
                    @input.native="onChangeTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Price Previous -->
            <b-col md="3">
              <b-form-group
                label="Price"
                label-for="product-edit-title"
                class="mb-2"
              >
                <b-form-input
                  id="product-edit-title"
                  v-model="productEdit.from_price"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- Price Edit -->
            <b-col md="3">
              <b-form-group
                label-for="product-edit-title"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-title"
                    v-model="productOnEdit.from_price"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Slug Previous -->
            <b-col md="3">
              <b-form-group
                label="Slug"
                label-for="product-edit-slug"
                class="mb-2"
              >
                <b-form-input
                  id="product-edit-slug"
                  v-model="productEdit.slug"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- Slug Edit-->
            <b-col md="3">
              <b-form-group
                label-for="product-edit-slug"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Slug"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-slug"
                    v-model="productOnEdit.slug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Category Previous -->
            <b-col md="3">
              <b-form-group
                label="Category"
                label-for="product-edit-category"
                class="mb-2"
              >
                <v-select
                  id="product-edit-category"
                  v-model="productEdit.category_ids"
                  disabled
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productCategory"
                />
              </b-form-group>
            </b-col>
            <!-- Category edit-->
            <b-col md="3">
              <b-form-group
                label-for="product-edit-category"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <v-select
                    id="product-edit-category"
                    v-model="productOnEdit.category_ids"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Tags Previous-->
            <b-col md="3">
              <b-form-group
                v-if="productTags.length > 0"
                label="Tags"
                label-for="tags-edit-vendor"
                class="mb-2"
              >
                <v-select
                  id="region-edit-vendor"
                  v-model="productEdit.tag_ids"
                  disabled
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productTags"
                />
              </b-form-group>
            </b-col>
            <!-- Tags Edit-->
            <b-col md="3">
              <b-form-group
                v-if="productTags.length > 0"
                label-for="tags-edit-vendor"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Tags"
                  rules="required"
                >
                  <v-select
                    id="region-edit-vendor"
                    v-model="productOnEdit.tag_ids"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productTags"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Roles Previous-->
            <b-col md="3">
              <b-form-group
                v-if="productTags.length > 0"
                label="Roles"
                label-for="tags-edit-vendor"
                class="mb-2"
              >
                <v-select
                  id="region-edit-vendor"
                  v-model="productEdit.product_roles"
                  disabled
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productRole"
                />
              </b-form-group>
            </b-col>
            <!-- Roles Edit-->
            <b-col md="3">
              <b-form-group
                v-if="productTags.length > 0"
                label-for="tags-edit-vendor"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Roles"
                  rules="required"
                >
                  <v-select
                    id="region-edit-vendor"
                    v-model="productOnEdit.product_roles"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productRole"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Bussiness challenge Previous-->
            <b-col md="3">
              <b-form-group
                v-if="productTags.length > 0"
                label="Business Challenges"
                label-for="tags-edit-vendor"
                class="mb-2"
              >
                <v-select
                  id="region-edit-vendor"
                  v-model="productEdit.product_bussiness_challenges"
                  disabled
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productBussiess"
                />
              </b-form-group>
            </b-col>
            <!-- Bussiness challenge Edit-->
            <b-col md="3">
              <b-form-group
                v-if="productTags.length > 0"
                label-for="tags-edit-vendor"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Business Challenges"
                  rules="required"
                >
                  <v-select
                    id="region-edit-vendor"
                    v-model="productOnEdit.product_bussiness_challenges"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productBussiess"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Product Type Previous-->
            <b-col md="3">
              <b-form-group
                v-if="productType.length > 0"
                label="Asset type"
                label-for="product-edit-type"
                class="mb-2"
              >
                <v-select
                  id="product-edit-type"
                  v-model="productEdit.type"
                  disabled
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productType"
                  @input="(val) => updateProductType(val)"
                />
              </b-form-group>
            </b-col>
            <!-- Product Type Edit-->
            <b-col md="3">
              <b-form-group
                v-if="productType.length > 0"
                label-for="product-edit-type"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Asset type"
                  rules="required"
                >
                  <v-select
                    id="product-edit-type"
                    v-model="productOnEdit.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productType"
                    @input="(val) => updateProductType(val)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-row>

              <!-- description form previous -->
              <b-col
                md="6"
                class="mb-2"
              >
                <transition-group
                  name="custom-classes"
                  enter-active-class="animate__animated animate__fadeInDown"
                  leave-active-class="animate__animated animate__fadeOutUp"
                  :duration="300"
                >
                  <b-card-body
                    v-for="index in count"
                    :key="index"
                  >
                    <!-- repeater from -->
                    <div class="border rounded p-2">
                      <!-- Language -->
                      <b-col md="12">
                        <b-form-group
                          label="Language"
                          label-for="product-edit-region"
                          class="mb-2"
                        >
                          <v-select
                            id="region-edit-region"
                            v-model="arrayDescription[index - 1].region_id"
                            disabled
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="languageOptions"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Title -->
                      <b-col md="12">
                        <b-form-group
                          label="Title"
                          label-for="product-edit-title"
                          class="mb-2"
                        >
                          <b-form-input
                            id="product-edit-title"
                            v-model="arrayDescription[index - 1].title"
                            disabled
                          />

                        </b-form-group>
                      </b-col>
                      <!-- Summary -->
                      <b-col md="12">
                        <b-form-group
                          label="Summary"
                          label-for="product-add-Summary"
                          class="mb-2"
                        >
                          <b-form-input
                            id="product-add-Summary"
                            v-model="arrayDescription[index - 1].summary"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Asset overview -->
                      <b-col cols="12">
                        <b-form-group
                          label="Asset overview"
                          label-for="product-orverview"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Asset overview"
                            rules="required"
                          >
                            <quill-editor
                              id="product-overview"
                              v-model="arrayDescription[index - 1].description"
                              disabled
                              class="editor-form"
                              :options="snowOption"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Recommended for -->
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Recommended for"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'myRecommended' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescription[index -1].recomendFors.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescription[index -1].recomendFors.length">
                                <swiper-slide
                                  v-for="(data,indexRecommend) in arrayDescription[index -1].recomendFors"
                                  :key="indexRecommend + 'recommend' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="border: dashed 2px;
                                    border-radius: 1rem;
                                    border-color: #DB536A;
                                    height: 10rem; justify-content: center;"
                                    >
                                      <b-col
                                        cols="10"
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image">Select image</span>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Image"
                                            rules="required"
                                          >
                                            <b-img
                                              v-if="data.image"
                                              v-model="arrayDescription[index -1].recomendFors[indexRecommend].image"
                                              fluid
                                              :src="data.image.link"
                                              style="height: 10rem; width: 20rem"
                                            />

                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                      <b-form-input
                                        id="product-add-Summary"
                                        v-model="arrayDescription[index -1].recomendFors[indexRecommend].description"
                                        disabled
                                      />
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('myRecommended' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('myRecommended' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <!-- Issues it solves -->
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Issues it solves"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'issueSwiper' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescription[index -1].issueItFor.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescription[index -1].issueItFor.length">

                                <swiper-slide
                                  v-for="(data,indexIssue) in arrayDescription[index -1].issueItFor"
                                  :key="indexIssue + 'issue' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="border: dashed 2px;
                                    border-radius: 1rem;
                                    border-color: #DB536A; height: 10rem; justify-content: center;"
                                    >
                                      <b-col
                                        cols="10"
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image">Select image</span>
                                          <b-img
                                            v-if="data.image"
                                            v-model="arrayDescription[index -1].issueItFor[indexIssue].image"
                                            fluid
                                            :src="data.image.link"
                                            style="height: 10rem; width: 20rem"
                                          />
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                      <b-form-input
                                        id="product-add-Summary"
                                        v-model="arrayDescription[index -1].issueItFor[indexIssue].description"
                                        disabled
                                      />
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('issueSwiper' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('issueSwiper' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- Benefits -->
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Benefits"
                            label-for="product-benefits"
                          >
                            <swiper
                              :ref="'benefit' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem; height: 20rem"
                              class="swiper-multiple"
                              :options="swiperOptionsBenefits"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescription[index -1].benefits.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescription[index -1].benefits.length">
                                <swiper-slide
                                  v-for="(data,indexBenefit) in arrayDescription[index -1].benefits"
                                  :key="indexBenefit + 'benefit' + index"
                                >
                                  <b-row style="justify-content: center;">
                                    <b-col
                                      cols="4"
                                      style="border: dashed 2px; border-radius: 1rem; border-color: #DB536A;"
                                    >
                                      <div class="select-image">
                                        <span v-if="!data.image">Select image</span>
                                        <b-img
                                          v-if="data.image"
                                          v-model="arrayDescription[index -1].benefits[indexBenefit].image"
                                          fluid
                                          :src="data.image.link"
                                          style="height: 10rem; width: 20rem"
                                        />
                                      </div>
                                    </b-col>
                                    <b-col cols="6">
                                      <b-form-group
                                        label="Title"
                                      >
                                          <b-form-input
                                            id="product-add-Summary"
                                            disabled
                                            v-model="arrayDescription[index -1].benefits[indexBenefit].title"
                                          />
                                      </b-form-group>
                                      <b-form-group
                                        label="Content"
                                      >
                                       <div 
                                        v-for="itemContent, indexContent in arrayDescription[index -1].benefits[indexBenefit].content"
                                        class="mb-1"
                                        :key="indexContent"
                                        >
                                          <div class="d-flex" style="justify-content: center; align-items: center;">
                                            <div style="width: 100%">
                                                <b-form-input
                                                id="product-add-Summary"
                                                disabled
                                                v-model="arrayDescription[index -1].benefits[indexBenefit].content[indexContent]"
                                              />
                                            </div>
                                          </div>
                                       </div>
                              
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('benefit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('benefit' + index)"
                              />
                            </swiper>
                          </b-form-group>

                        </b-col>
                      </b-row>
                      <!-- PwC Service -->
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="PwC Service"
                            label-for="product-benefits"
                          >
                            <swiper
                              :ref="'service' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem; height: 22rem"
                              class="swiper-multiple"
                              :options="swiperOptionsBenefits"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescription[index -1].pwcServices.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescription[index -1].pwcServices.length">
                                <swiper-slide
                                  v-for="(data,indexService) in arrayDescription[index -1].pwcServices"
                                  :key="indexService + 'service' + index"
                                >
                                  <b-row style="justify-content: center;">
                                    <b-col
                                      cols="4"
                                      style="border: dashed 2px; border-radius: 1rem; border-color: #DB536A;"
                                    >
                                      <div class="select-image">
                                        <span v-if="!data.image">Select image</span>
                                        <b-img
                                          v-if="data.image"
                                          v-model="arrayDescription[index -1].pwcServices[indexService].image"
                                          fluid
                                          :src="data.image.link"
                                          style="height: 10rem; width: 20rem"
                                        />
                                      </div>
                                    </b-col>
                                    <b-col cols="6">
                                      <b-form-group
                                        label="Title"
                                      >
                                          <b-form-input
                                            id="product-add-Summary"
                                            disabled
                                            v-model="arrayDescription[index -1].pwcServices[indexService].title"
                                          />
                                      </b-form-group>
                                      <b-form-group
                                        label="Description"
                                      >
                                          <b-form-textarea
                                            v-model="arrayDescription[index -1].pwcServices[indexService].description"
                                            id="textarea-default"
                                            placeholder="Textarea"
                                            disabled
                                            rows="2"
                                          />
                                      </b-form-group>
                                      <b-form-group
                                        label="Content"
                                      >
                                       <div 
                                        v-for="itemContent, indexContent in arrayDescription[index -1].pwcServices[indexService].content"
                                        class="mb-1"
                                        :key="indexContent"
                                        >
                                          <div class="d-flex" style="justify-content: center; align-items: center;">
                                            <div style="width: 100%">
                                                <b-form-input
                                                id="product-add-Summary"
                                                disabled
                                                v-model="arrayDescription[index -1].pwcServices[indexService].content[indexContent]"
                                              />
                                            </div>
                                          </div>
                                       </div>
                              
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('service' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('service' + index)"
                              />
                            </swiper>
                          </b-form-group>

                        </b-col>
                      </b-row>
                      <!-- PwC Teams -->
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="PwC Teams"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'team' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescription[index -1].pwcTeams.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescription[index -1].pwcTeams.length">
                                <swiper-slide
                                  v-for="(data,indexTeam) in arrayDescription[index -1].pwcTeams"
                                  :key="indexTeam + 'team' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="border: dashed 2px;
                                    border-radius: 1rem;
                                    border-color: #DB536A; height: 10rem; justify-content: center;"
                                    >
                                      <b-col
                                        cols="10"
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image">Select image</span>
                                          <b-img
                                            v-if="data.image"
                                            v-model="arrayDescription[index -1].pwcTeams[indexTeam].image"
                                            fluid
                                            :src="data.image.link"
                                            style="height: 10rem; width: 20rem"
                                          />
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                      <b-col>
                                        <b-form-input
                                          id="product-add-Summary"
                                          v-model="arrayDescription[index -1].pwcTeams[indexTeam].name"
                                          disabled
                                        />
                                      </b-col>
                                      <b-col>
                                        <b-form-input
                                          id="product-add-Summary"
                                          v-model="arrayDescription[index -1].pwcTeams[indexTeam].role"
                                          disabled
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('team' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('team' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- PwC Team Over all -->
                      <b-col cols="12">
                        <b-form-group
                          label="PwC Team Over all"
                          label-for="product-benefits"
                        >
                          <b-form-textarea
                          v-model="arrayDescription[index -1].teamOverAll"
                          id="textarea-default"
                          placeholder="Textarea"
                          rows="3"
                        />
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-card-body>
                </transition-group>
              </b-col>
              <!-- description form Edit -->
              <b-col
                :key="componentKey"
                md="6"
                class="mb-2"
              >
                <transition-group
                  name="custom-classes"
                  enter-active-class="animate__animated animate__fadeInDown"
                  leave-active-class="animate__animated animate__fadeOutUp"
                  :duration="300"
                >
                  <b-card-body
                    v-for="index in countOnEdit"
                    :key="index"
                  >
                    <!-- repeater from -->
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescriptionOnEdit.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index - 1)"
                        />
                      </div>
                      <!-- Language -->
                      <b-col md="12">
                        <b-form-group
                          label="Language"
                          label-for="product-edit-region"
                          class="mb-2"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Language"
                            rules="required"
                          >
                            <v-select
                              id="region-edit-region"
                              v-model="arrayDescriptionOnEdit[index - 1].region_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="languageOptions"
                              :clearable="false"
                              :disabled="index === 1"
                              @input="(val) => updateItemForm(index - 1, val)"
                              :selectable="(option) => {
                                  return !arrayDescriptionOnEdit.find(x => x.region_id.value === option.value)
                                }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Title -->
                      <b-col md="12">
                        <b-form-group
                          label="Title"
                          label-for="product-edit-title"
                          class="mb-2"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Title"
                            rules="required"
                          >
                            <b-form-input
                              id="product-edit-title"
                              v-model="arrayDescriptionOnEdit[index - 1].title"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Summary -->
                      <b-col md="12">
                        <b-form-group
                          label="Summary"
                          label-for="product-add-Summary"
                          class="mb-2"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Summary"
                            rules="required"
                          >
                            <b-form-input
                              id="product-add-Summary"
                              v-model="arrayDescriptionOnEdit[index - 1].summary"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Asset overview -->
                      <b-col cols="12">
                        <b-form-group
                          label="Asset overview"
                          label-for="product-orverview"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Asset overview"
                            rules="required"
                          >
                            <quill-editor
                              id="product-overview"
                              v-model="arrayDescriptionOnEdit[index - 1].description"
                              class="editor-form"
                              :options="snowOption"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Recommended for -->
                      <b-row>
                        <b-col cols="10">
                          <b-form-group
                            label="Recommended for"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'myRecommendedOnedit' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescriptionOnEdit[index -1].recomendFors.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescriptionOnEdit[index -1].recomendFors.length">
                                <swiper-slide
                                  v-for="(data,indexRecommend) in arrayDescriptionOnEdit[index -1].recomendFors"
                                  :key="indexRecommend + 'recommend' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="border: dashed 2px;
                                    border-radius: 1rem;
                                    border-color: #DB536A;
                                  cursor: pointer; height: 10rem; justify-content: center;"
                                    >
                                      <b-col
                                        cols="10"
                                        @click="openSetImageModal(index - 1, indexRecommend, 1, 'recomendFors')"
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image">Select image</span>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Image"
                                            rules="required"
                                          >
                                            <b-img
                                              v-if="data.image"
                                              v-model="arrayDescriptionOnEdit[index -1].recomendFors[indexRecommend].image"
                                              fluid
                                              :src="data.image.link"
                                              style="height: 10rem; width: 20rem"
                                            />

                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </div>

                                      </b-col>
                                      <b-col
                                        cols="2"
                                        class="back-sub-element"
                                        @click="removeElemen(index -1, indexRecommend, 'myRecommendedOnedit' + index, 'recomendFors')"
                                      >
                                        <div
                                          class="sub-element"
                                        >
                                          -
                                        </div>
                                      </b-col>

                                    </b-row>
                                    <b-row class="mt-1">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        style="width: 100%"
                                        name="Description"
                                        rules="required"
                                      >
                                        <b-form-input
                                          id="product-add-Summary"
                                          v-model="arrayDescriptionOnEdit[index -1].recomendFors[indexRecommend].description"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>

                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('myRecommendedOnedit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('myRecommendedOnedit' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="2"
                          style="padding: 1rem;"
                          @click="appendElemen(index-1, 'myRecommendedOnedit' + index, 'recomendFors')"
                        >
                          <div class="add-element">
                            +
                          </div>
                        </b-col>
                      </b-row>
                      <!-- Issues it solves -->
                      <b-row>
                        <b-col
                          cols="10"
                          style="display:flex; flex-direction: column; justify-content: center;"
                        >
                          <b-form-group
                            label="Issues it solves"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'issueSwiperOnedit' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescriptionOnEdit[index -1].issueItFor.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescriptionOnEdit[index -1].issueItFor.length">
                                <swiper-slide
                                  v-for="(data,indexIssue) in arrayDescriptionOnEdit[index -1].issueItFor"
                                  :key="indexIssue + 'issue' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="border: dashed 2px;
                                    border-radius: 1rem;
                                    border-color: #DB536A;
                                    cursor: pointer; height: 10rem; justify-content: center;"
                                    >
                                      <b-col
                                        cols="10"
                                        @click="openSetImageModal(index - 1, indexIssue, 2, 'issueItFor')"
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image">Select image</span>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Image"
                                            rules="required"
                                          >
                                            <b-img
                                              v-if="data.image"
                                              v-model="arrayDescriptionOnEdit[index -1].issueItFor[indexIssue].image"
                                              fluid
                                              :src="data.image.link"
                                              style="height: 10rem; width: 20rem"
                                            />

                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </div>
                                      </b-col>
                                      <b-col
                                        cols="2"
                                        class="back-sub-element"
                                        @click="removeElemen(index -1, indexIssue, 'issueSwiperOnedit' + index, 'issueItFor')"
                                      >
                                        <div
                                          class="sub-element"
                                        >
                                          -
                                        </div>
                                      </b-col>

                                    </b-row>
                                    <b-row class="mt-1">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        style="width: 100%"
                                        name="Description"
                                        rules="required"
                                      >
                                        <b-form-input
                                          id="product-add-Summary"
                                          v-model="arrayDescriptionOnEdit[index -1].issueItFor[indexIssue].description"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('issueSwiperOnedit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('issueSwiperOnedit' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="2"
                          style="padding: 1rem;"
                          @click="appendElemen(index-1, 'issueSwiperOnedit' + index, 'issueItFor')"
                        >
                          <div class="add-element">
                            +
                          </div>
                        </b-col>
                      </b-row>
                      <!-- Benefits -->
                      <b-row>
                        <b-col
                          cols="10"
                          style="display:flex; flex-direction: column; justify-content: center;"
                        >
                          <b-form-group
                            label="Benefits"
                            label-for="product-benefits"
                          >
                            <swiper
                              :ref="'benefitOnedit' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptionsBenefits"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescriptionOnEdit[index -1].benefits.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescriptionOnEdit[index -1].benefits.length">
                                <swiper-slide
                                  v-for="(data,indexBenefit) in arrayDescriptionOnEdit[index -1].benefits"
                                  :key="indexBenefit + 'benefit' + index"
                                >
                                  <b-row style="justify-content: center;">
                                    <b-col
                                      cols="4"
                                      style="border: dashed 2px; border-radius: 1rem; border-color: #DB536A; cursor: pointer;"
                                      @click="openSetImageModal(index - 1, indexBenefit, 3, 'benefits')"
                                    >
                                      <div class="select-image">
                                        <span v-if="!data.image">Select image</span>
                                          <b-img
                                            v-if="data.image"
                                            v-model="arrayDescriptionOnEdit[index -1].benefits[indexBenefit].image"
                                            fluid
                                            :src="data.image.link"
                                            style="height: 10rem; width: 20rem"
                                          />
                                      </div>
                                    </b-col>
                                    <b-col cols="5">
                                      <b-form-group                                     
                                        label="Title"
                                      >
                                          <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Description"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="product-add-Summary"
                                            v-model="arrayDescriptionOnEdit[index -1].benefits[indexBenefit].title"
                                          />
                                          
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider> 
                                      </b-form-group>

                                      <b-form-group
                                        label="Content"
                                      >
                                       <div 
                                        v-for="itemContent, indexContent in arrayDescriptionOnEdit[index -1].benefits[indexBenefit].content"
                                        class="mb-1"
                                        :key="indexContent"
                                        >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Content"
                                          rules="required"
                                        >
                                          <div class="d-flex" style="justify-content: center; align-items: center;">
                                            <div style="width: 100%">
                                                <b-form-input
                                                id="product-add-Summary"
                                                v-model="arrayDescriptionOnEdit[index -1].benefits[indexBenefit].content[indexContent]"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </div>
                                            <feather-icon
                                                v-if="indexContent === 0"
                                                size="16"
                                                icon="PlusCircleIcon"
                                                class="cursor-pointer ml-1"
                                                @click="addContentForm(index - 1, 'benefits', indexBenefit, 'benefitOnedit' + index)"
                                              />
                                            <feather-icon
                                                v-else
                                                size="16"
                                                icon="XCircleIcon"
                                                class="cursor-pointer ml-1"
                                                @click="removeContentForm(index - 1, 'benefits', indexBenefit, indexContent, 'benefitOnedit' + index)"
                                              />
                                          </div>
                                        </validation-provider> 
                                       </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col
                                      cols="1"
                                      class="back-sub-element"
                                      @click="removeElemen(index - 1, indexBenefit, 'benefitOnedit' + index, 'benefits')"
                                    >
                                      <div
                                        class="sub-element"
                                      >
                                        -
                                      </div>
                                    </b-col>
                                  </b-row>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('benefitOnedit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('benefitOnedit' + index)"
                              />
                            </swiper>
                          </b-form-group>

                        </b-col>
                        <b-col
                          cols="2"
                          style="padding: 1rem;"
                          @click="appendElemen(index-1, 'benefitOnedit' + index, 'benefits')"
                        >
                          <div class="add-element">
                            +
                          </div>
                        </b-col>
                      </b-row>
                      <!-- PwC Service -->
                      <b-row>
                        <b-col
                          cols="10"
                          style="display:flex; flex-direction: column; justify-content: center;"
                        >
                          <b-form-group
                            label="PwC Service"
                            label-for="product-benefits"
                          >
                            <swiper
                              :ref="'serviceOnedit' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptionsBenefits"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescriptionOnEdit[index -1].pwcServices.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescriptionOnEdit[index -1].pwcServices.length">
                                <swiper-slide
                                  v-for="(data,indexService) in arrayDescriptionOnEdit[index -1].pwcServices"
                                  :key="indexService + 'service' + index"
                                >
                                  <b-row style="justify-content: center;">
                                    <b-col
                                      cols="4"
                                      style="border: dashed 2px; border-radius: 1rem; border-color: #DB536A; cursor: pointer;"
                                      @click="openSetImageModal(index - 1, indexService, 4, 'pwcServices')"
                                    >
                                      <div class="select-image">
                                        <span v-if="!data.image">Select image</span>
                                          <b-img
                                            v-if="data.image"
                                            v-model="arrayDescriptionOnEdit[index -1].pwcServices[indexService].image"
                                            fluid
                                            :src="data.image.link"
                                            style="height: 10rem; width: 20rem"
                                          />
                                      </div>
                                    </b-col>
                                    <b-col cols="5">
                                      <b-form-group
                                        label="Title"
                                      >
                                          <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Description"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="product-add-Summary"
                                            v-model="arrayDescriptionOnEdit[index -1].pwcServices[indexService].title"
                                          />
                                          
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider> 
                                      </b-form-group>
                                      <b-form-group
                                        label="Description"
                                      >
                                          <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Description"
                                          rules="required"
                                        >
                                          <b-form-textarea
                                            v-model="arrayDescriptionOnEdit[index -1].pwcServices[indexService].description"
                                            id="textarea-default"
                                            placeholder="Textarea"
                                            rows="2"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider> 
                                      </b-form-group>
                                      <b-form-group
                                        label="Content"
                                      >
                                       <div 
                                        v-for="itemContent, indexContent in arrayDescriptionOnEdit[index -1].pwcServices[indexService].content"
                                        class="mb-1"
                                        :key="indexContent"
                                        >
                                          <div class="d-flex" style="justify-content: center; align-items: center;">
                                            <div style="width: 100%">
                                                <b-form-input
                                                id="product-add-Summary"
                                                v-model="arrayDescriptionOnEdit[index -1].pwcServices[indexService].content[indexContent]"
                                              />
                                            </div>
                                            <feather-icon
                                                v-if="indexContent === 0"
                                                size="16"
                                                icon="PlusCircleIcon"
                                                class="cursor-pointer ml-1"
                                                @click="addContentForm(index - 1, 'pwcServices', indexService, 'serviceOnedit' + index)"
                                              />
                                            <feather-icon
                                                v-else
                                                size="16"
                                                icon="XCircleIcon"
                                                class="cursor-pointer ml-1"
                                                @click="removeContentForm(index - 1, 'pwcServices', indexService, indexContent, 'serviceOnedit' + index)"
                                              />
                                          </div>
                                       </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col
                                      cols="1"
                                      class="back-sub-element"
                                      @click="removeElemen(index - 1, indexService, 'serviceOnedit' + index, 'pwcServices')"
                                    >
                                      <div
                                        class="sub-element"
                                      >
                                        -
                                      </div>
                                    </b-col>
                                  </b-row>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('serviceOnedit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('serviceOnedit' + index)"
                              />
                            </swiper>
                          </b-form-group>

                        </b-col>
                        <b-col
                          cols="2"
                          style="padding: 1rem;"
                          @click="appendElemen(index-1, 'serviceOnedit' + index, 'pwcServices')"
                        >
                          <div class="add-element">
                            +
                          </div>
                        </b-col>
                      </b-row>
                      <!-- PwC Teams -->
                      <b-row>
                        <b-col
                          cols="10"
                          style="display:flex; flex-direction: column; justify-content: center;"
                        >
                          <b-form-group
                            label="PwC Teams"
                            label-for="product-recommended"
                          >
                            <swiper
                              :ref="'teamOnedit' + index"
                              style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; width: 36rem;"
                              class="swiper-multiple"
                              :options="swiperOptions"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :style="!arrayDescriptionOnEdit[index -1].pwcTeams.length ? 'height: 16rem;' :''"
                            >
                              <template v-if="arrayDescriptionOnEdit[index -1].pwcTeams.length">
                                <swiper-slide
                                  v-for="(data,indexTeam) in arrayDescriptionOnEdit[index -1].pwcTeams"
                                  :key="indexTeam + 'team' + index"
                                >
                                  <b-col>
                                    <b-row
                                      style="border: dashed 2px;
                                    border-radius: 1rem;
                                    border-color: #DB536A;
                                    cursor: pointer; height: 10rem; justify-content: center;"
                                    >
                                      <b-col
                                        cols="10"
                                        @click="openSetImageModal(index - 1, indexTeam, 5, 'pwcTeams')"
                                      >
                                        <div class="select-image">
                                          <span v-if="!data.image">Select image</span>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            style="width: 100%"
                                            name="Image"
                                            rules="required"
                                          >
                                            <b-img
                                              v-if="data.image"
                                              v-model="arrayDescriptionOnEdit[index -1].pwcTeams[indexTeam].image"
                                              fluid
                                              :src="data.image.link"
                                              style="height: 10rem; width: 20rem"
                                            />

                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </div>
                                      </b-col>
                                      <b-col
                                        cols="2"
                                        class="back-sub-element"
                                        @click="removeElemen(index - 1, indexTeam, 'teamOnedit' + index, 'pwcTeams')"
                                      >
                                        <div
                                          class="sub-element"
                                        >
                                          -
                                        </div>
                                      </b-col>

                                    </b-row>
                                    <b-row class="mt-1">
                                      <b-col>
                                        <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Name"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="product-add-Summary"
                                            v-model="arrayDescriptionOnEdit[index -1].pwcTeams[indexTeam].name"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                      </b-col> <b-col>
                                        <validation-provider
                                          v-slot="{ errors }"
                                          style="width: 100%"
                                          name="Description"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="product-add-Summary"
                                            v-model="arrayDescriptionOnEdit[index -1].pwcTeams[indexTeam].role"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </swiper-slide>
                              </template>
                              <template v-else>
                                <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                                  No item added yet!
                                </div>
                              </template>
                              <!-- Add Arrows -->
                              <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextSlide('teamOnedit' + index)"
                              />
                              <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="previousSlide('teamOnedit' + index)"
                              />
                            </swiper>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="2"
                          style="padding: 1rem;"
                          @click="appendElemen(index-1, 'issueSwiperOnedit' + index, 'issueItFor')"
                        >
                          <div class="add-element">
                            +
                          </div>
                        </b-col>
                      </b-row>
                      <!-- PwC Team Over all -->
                      <b-col cols="12">
                        <b-form-group
                          label="PwC Team Over all"
                          label-for="product-benefits"
                        >
                          <b-form-textarea
                          v-model="arrayDescriptionOnEdit[index -1].teamOverAll"
                          id="textarea-default"
                          placeholder="Textarea"
                          rows="3"
                        />
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-card-body>
                </transition-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-1"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDescription"
                >
                  Add description form
                </b-button>
              </b-col>
            </b-row>

            <!-- Related resources-->
            <b-col
              cols="6"
              class="mt-2"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Related resources"
                    label-for="product-benefits"
                  >
                    <swiper
                      v-if="!productEdit.relatedSource.length"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in 1"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="border: solid 2px; border-radius: 1rem; border-color: #DB536A"
                          >
                            <div
                              class="related-source"
                              style="height: 12rem;"
                            >
                              <span v-if="!data.image">No offering added yet</span>
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                      />
                    </swiper>
                    <swiper
                      v-else
                      ref="relatedSource"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 23rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in productEdit.relatedSource"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-radius: 1rem;
                              border-color: #DB536A"
                          >
                            <div
                              class="mt-2 font-weight-bold mb-2"
                            >
                              {{ data.title }}
                            </div>
                            <div
                              class="related-source"
                              style="height: 15rem; margin-bottom: 1rem;"
                            >
                              <b-img
                                fluid
                                v-if="data.images.length > 0"
                                :src="data.images.find(x => x.type === 'header_image') ? 
                                 data.images.find(x => x.type === 'header_image').link : ''"
                                style="height: 15rem"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlideRelated('relatedSource')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlideRelated('relatedSource')"
                      />
                    </swiper>
                  </b-form-group>

                </b-col>
              </b-row>
            </b-col>
            <b-col
              :key="componentKey"
              cols="6"
              class="mt-2"
            >
              <b-row>
                <b-col
                  cols="10"
                >
                  <b-form-group
                    class="mt-2"
                    label-for="product-benefits"
                  >
                    <swiper
                      v-if="!productOnEdit.relatedSource.length"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 16rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in 1"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="border: solid 2px; border-radius: 1rem; border-color: #DB536A"
                          >
                            <div
                              class="related-source"
                              style="height: 12rem;"
                            >
                              <span v-if="!data.image">No offering added yet</span>
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                      />
                    </swiper>
                    <swiper
                      v-else
                      ref="relatedSourceOnEdit"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 23rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in productOnEdit.relatedSource"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-top-left-radius: 1rem;
                              border-bottom-left-radius: 1rem;
                              border-color: #DB536A"
                          >
                            <div
                              class="mt-2 font-weight-bold mb-2"
                            >
                              {{ data.title }}
                            </div>
                            <div
                              class="related-source"
                              style="height: 15rem; margin-bottom: 1rem;"
                            >
                              <b-img
                                fluid
                                v-if="data.images.length > 0"
                                :src="data.images.find(x => x.type === 'header_image') ? 
                                 data.images.find(x => x.type === 'header_image').link : ''"
                                style="height: 15rem"
                              />
                            </div>
                          </b-col>
                          <b-col
                            cols="1"
                            class="back-sub-element"
                            @click="removeElementRelated(indexRelated, 'relatedSource', 'relatedSourceOnEdit')"
                          >
                            <div
                              class="sub-element"
                            >
                              -
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlideRelated('relatedSourceOnEdit')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlideRelated('relatedSourceOnEdit')"
                      />
                    </swiper>
                  </b-form-group>

                </b-col>
                <b-col
                  cols="2"
                  style="padding: 1rem;"
                  @click="isShowModalSelectRelate =! isShowModalSelectRelate"
                >
                  <div class="add-element">
                    +
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Similar asset -->
            <b-col
              cols="6"
              class="mt-2"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Similar asset"
                    label-for="product-benefits"
                  >
                    <swiper
                      v-if="!productEdit.smilarAssets.length"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 16rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in 1"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="border: solid 2px; border-radius: 1rem; border-color: #DB536A"
                          >
                            <div
                              class="related-source"
                              style="height: 12rem;"
                            >
                              <span v-if="!data.image">No offering added yet</span>
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                      />
                    </swiper>
                    <swiper
                      v-else
                      ref="relatedSource"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 23rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in productEdit.relatedSource"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-radius: 1rem;
                              border-color: #DB536A"
                          >
                            <div
                              class="mt-2 font-weight-bold mb-2"
                            >
                              {{ data.title }}
                            </div>
                            <div
                              class="related-source"
                              style="height: 15rem; margin-bottom: 1rem;"
                            >
                              <b-img
                                v-if="data.images.length > 0"
                                fluid
                                :src="data.images.find(x => x.type === 'header_image') ? 
                                 data.images.find(x => x.type === 'header_image').link : ''"
                                style="height: 15rem"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlideRelated('relatedSource')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlideRelated('relatedSource')"
                      />
                    </swiper>
                  </b-form-group>

                </b-col>
              </b-row>
            </b-col>
            <b-col
              :key="componentKey"
              cols="6"
              class="mt-2"
            >
              <b-row>
                <b-col
                  cols="10"
                >
                  <b-form-group
                    class="mt-2"
                    label-for="product-benefits"
                  >
                    <swiper
                      v-if="!productOnEdit.smilarAssets.length"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 16rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in 1"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="border: solid 2px; border-radius: 1rem; border-color: #DB536A"
                          >
                            <div
                              class="related-source"
                              style="height: 12rem;"
                            >
                              <span v-if="!data.image">No offering added yet</span>
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                      />
                    </swiper>
                    <swiper
                      v-else
                      ref="smilarAssetsOnEdit"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem; height: 23rem;"

                      class="swiper-multiple"
                      :options="swiperOptionsBenefits"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                      <swiper-slide
                        v-for="(data,indexRelated) in productOnEdit.smilarAssets"
                        :key="indexRelated + 'related'"
                      >
                        <b-row style="justify-content: center;">
                          <b-col
                            cols="6"
                            style="
                              border: solid 2px;
                              border-top-left-radius: 1rem;
                              border-bottom-left-radius: 1rem;
                              border-color: #DB536A"
                          >
                            <div
                              class="mt-2 font-weight-bold mb-2"
                            >
                              {{ data.title }}
                            </div>
                            <div
                              class="related-source"
                              style="height: 15rem; margin-bottom: 1rem;"
                            >
                              <b-img
                                v-if="data.images.length > 0"
                                fluid
                                :src="data.images.find(x => x.type === 'header_image') ? 
                                 data.images.find(x => x.type === 'header_image').link : ''"
                                style="height: 15rem"
                              />
                            </div>
                          </b-col>
                          <b-col
                            cols="1"
                            class="back-sub-element"
                            @click="removeElementRelated(indexRelated, 'smilarAssets', 'smilarAssetsOnEdit')"
                          >
                            <div
                              class="sub-element"
                            >
                              -
                            </div>
                          </b-col>
                        </b-row>
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlideRelated('smilarAssetsOnEdit')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlideRelated('smilarAssetsOnEdit')"
                      />
                    </swiper>
                  </b-form-group>

                </b-col>
                <b-col
                  cols="2"
                  style="padding: 1rem;"
                  @click="isShowModalSelectSimilar =! isShowModalSelectSimilar"
                >
                  <div class="add-element">
                    +
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-row>
                  <!-- Asset overview -->
                  <b-col md="4">
                    <b-form-group
                      label="Asset overview previous"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <div class="border rounded p-2 pb-0 mt-2">
                        <h6 v-if="imageOverview.length === 0">
                          No photos added yet
                        </h6>
                        <b-img
                          v-else
                          v-model="imageOverview"
                          center
                          fluid
                          :src="imageOverview[0].link"
                          height="100"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Header images -->
                  <b-col md="4">
                    <b-form-group
                      label="Header images previous"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <div class="border rounded p-2 pb-0 mt-2">
                        <h6 v-if="imageHeader.length === 0">
                          No photos added yet
                        </h6>
                        <b-img
                          v-else
                          v-model="imageHeader"
                          center
                          fluid
                          :src="imageHeader[0].link"
                          height="100"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- How it works medias -->
                  <b-col md="4">
                    <b-form-group
                      label="How it works medias previous"
                      label-for="product-add-title"
                      class="mb-2"
                    >
                      <div class="border rounded p-2 pb-0 mt-2">
                        <h6 v-if="imagesHowItWork.length === 0">
                          No photos added yet
                        </h6>
                        <swiper
                          v-if="imagesHowItWork.length > 0"
                          v-model="imagesHowItWork"
                          class="swiper-multiple"
                          style="width:30rem"
                          :options="swiperOptionsHowItWork"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        >
                          <swiper-slide
                            v-for="media in imagesHowItWork"
                            :key="media.id"
                          >
                            <b-link>
                              <div class="img-container">
                                <b-img
                                  v-if="media.media_type === 'image'"
                                  fluid
                                  :src="media.link"
                                  style="height: 10rem; width: 20rem"
                                />
                                <div
                                  v-else
                                  style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;"
                                >
                                  <b-embed
                                    type="video"
                                  >
                                    <source
                                      :src="media.link"
                                      type="video/mp4"
                                    >

                                  </b-embed>
                                </div>
                              </div></b-link>
                          </swiper-slide>
                        </swiper>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      label="Asset overview"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Asset overview"
                        rules="required"
                      >
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="primary"
                          @click="isShowModalSelectImageOverview =! isShowModalSelectImageOverview"
                        >
                          Select image
                        </b-button>
                        <div class="border rounded p-2 pb-0 mt-2">
                          <h6 v-if="imageOverviewOnEdit.length === 0">
                            No photos added yet
                          </h6>
                          <b-img
                            v-else
                            v-model="imageOverviewOnEdit"
                            center
                            fluid
                            :src="imageOverviewOnEdit[0].link"
                            height="100"
                          />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Header images -->
                  <b-col md="4">
                    <b-form-group
                      label="Header images"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Header images"
                        rules="required"
                      >
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="primary"
                          @click="isShowModalSelectImage =! isShowModalSelectImage"
                        >
                          Select image
                        </b-button>
                        <div class="border rounded p-2 pb-0 mt-2">
                          <h6 v-if="imageHeaderOnEdit.length === 0">
                            No photos added yet
                          </h6>
                          <b-img
                            v-else
                            v-model="imageHeaderOnEdit"
                            center
                            fluid
                            :src="imageHeaderOnEdit[0].link"
                            height="100"
                          />
                        </div>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- How it works medias -->
                  <b-col md="4">
                    <b-form-group
                      label="How it works medias"
                      label-for="product-add-title"
                      class="mb-2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="How it works medias"
                        rules=""
                      >
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="primary"
                          @click="isShowModalSelectImages =! isShowModalSelectImages"
                        >
                          Select images
                        </b-button>
                        <div class="border rounded p-2 pb-0 mt-2">
                          <h6 v-if="imagesHowItWorkOnEdit.length === 0">
                            No photos added yet
                          </h6>
                          <swiper
                            v-if="imagesHowItWorkOnEdit.length > 0"
                            v-model="imagesHowItWorkOnEdit"
                            class="swiper-multiple"
                            style="width:30rem"
                            :options="swiperOptionsHowItWork"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          >
                            <swiper-slide
                              v-for="media in imagesHowItWorkOnEdit"
                              :key="media.id"
                            >
                              <b-link>
                                <div class="img-container">
                                  <b-img
                                    v-if="media.media_type === 'image'"
                                    fluid
                                    :src="media.link"
                                    style="height: 10rem; width: 20rem"
                                  />
                                  <div
                                    v-else
                                    style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;"
                                  >
                                    <b-embed
                                      type="video"
                                    >
                                      <source
                                        :src="media.link"
                                        type="video/mp4"
                                      >

                                    </b-embed>
                                  </div>
                                </div></b-link>
                            </swiper-slide>
                          </swiper>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col
              cols="12"
              class="mt-2"
            >
              <b-button
                v-if="!idProduct"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmProduct"
              >
                Add Asset
              </b-button>
              <b-button
                v-if="idProduct"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmProduct"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'asset-manager' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      <!--/ form -->
      </validation-observer>
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalSelectImage"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageId"
      />
      <select-images-modal
        :is-show-modal-select-images.sync="isShowModalSelectImages"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-images-id="setImagesId"
      />
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalSelectImageOverview"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageIdOverview"
      />
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalRefType"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageRef"
      />
      <select-related-asset
        :is-show-modal-select-related-asset.sync="isShowModalSelectRelate"
        :region="productEdit.region_id"
        @set-asset-related="setAssetRelated"
      />
      <select-similar-asset
        :is-show-modal-select-similar-asset.sync="isShowModalSelectSimilar"
        offering-type="asset"
        :region="productEdit.region_id"
        @set-asset-similar="setAssetSimilar"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import {
  BImg,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BLink,
  BCardBody,
  BOverlay,
  BEmbed,
  BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import * as config from '@/utils/config'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
import SelectImageModal from '@/views/modal/SelectImageModal.vue'
import SelectImagesModal from '@/views/modal/SelectImagesModal.vue'
import SelectRelatedAsset from '@/views/modal/selectRelatedAsset/SelectRelatedAsset.vue'
import SelectSimilarAsset from '@/views/modal/selectSimilarAsset/SelectSimilarAsset.vue'
import * as utils from '@/utils/utils'
import editProduct from './onEditProduct'
import 'swiper/swiper-bundle.css'

export default {
  components: {
    BImg,
    SelectImageModal,
    SelectImagesModal,
    Swiper,
    SwiperSlide,
    BCardBody,
    BLink,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    SelectRelatedAsset,
    SelectSimilarAsset,
    BEmbed,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      swiperOptions: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOptionsBenefits: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      active: 'Active',
      inactive: 'Inactive',
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      readOnlyOption: {
        placeholder: 'Please choose region',
        theme: 'snow',
      },
      required,
      currentIndex: null,
      currentIndexRef: null,
      currentImageKey: '',
      refType: 0,
      componentKey: 0,
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions', 'languageOptions']),
    ...mapGetters('product', ['productCategory']),
    ...mapGetters('product', ['productTags']),
    ...mapGetters('product', ['productType']),
    ...mapGetters('bussiness', ['productBussiess']),
    ...mapGetters('role', ['productRole']),
  },
  async created() {
    this.loading = true
    // await store.dispatch('product/fetchCategories')
    await store.dispatch('product/fetchTags')
    // await store.dispatch('bussiness/fetchBussiness')
    // await store.dispatch('role/fetchRole')
    this.loading = false
  },
  setup() {
    const swiperOptionsHowItWork = {
      slidesPerView: 2,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    }
    const isShowModalRefType = ref(false)
    const isShowModalSelectImageOverview = ref(false)
    const isShowModalSelectImage = ref(false)
    const isShowModalSelectImages = ref(false)
    const isShowModalSelectRelate = ref(false)
    const isShowModalSelectSimilar = ref(false)
    const {
      arrayDescriptionOnEdit,
      countOnEdit,
      productOnEdit,
      imageHeader,
      imagesHowItWork,
      imageHeaderOnEdit,
      imagesHowItWorkOnEdit,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      updateItemForm,
      categorySelected,
      loading,
      imageOverviewOnEdit,
      imageOverview,
    } = editProduct()
    return {
      arrayDescriptionOnEdit,
      countOnEdit,
      productOnEdit,
      config,
      isShowModalSelectImage,
      isShowModalSelectImages,
      imageHeader,
      imagesHowItWork,
      imageHeaderOnEdit,
      imagesHowItWorkOnEdit,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      updateItemForm,
      categorySelected,
      loading,
      swiperOptionsHowItWork,
      isShowModalSelectImageOverview,
      isShowModalRefType,
      imageOverviewOnEdit,
      imageOverview,
      isShowModalSelectRelate,
      isShowModalSelectSimilar,
    }
  },

  methods: {
    async addContentForm(index, ref, subIndex, refIndex){
      await this.arrayDescriptionOnEdit[index][ref][subIndex].content.push('')
      this.forceUpdate()
      await this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1)
      console.log(this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1))
    },
    async removeContentForm(index, ref, subIndex, indexItem, refIndex){
      await this.arrayDescriptionOnEdit[index][ref][subIndex].content.splice(indexItem, 1)
      this.forceUpdate()
      await this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1)
      console.log(this.$refs[refIndex][0].$swiper.slideTo(subIndex + 1, 1))
    },
    async removeElementRelated(indexService, refIndex, key) {
      this.productOnEdit[refIndex].splice(indexService, 1)
      this.forceUpdate()
      await this.$refs[key].$swiper.slideTo(indexService - 1, 1)
    },
    setAssetSimilar(arrAsset) {
      this.productOnEdit.smilarAssets = arrAsset
    },
    setAssetRelated(arrAsset) {
      this.productOnEdit.relatedSource = arrAsset
    },
    async nextSlideRelated(refIndex) {
      console.log(this.$refs[refIndex])
      await this.$refs[refIndex].$swiper.slideNext()
    },
    async previousSlideRelated(refIndex) {
      await this.$refs[refIndex].$swiper.slidePrev()
    },
    async nextSlide(refIndex) {
      console.log(this.$refs[refIndex][0].$swiper)
      await this.$refs[refIndex][0].$swiper.slideNext()
    },
    async previousSlide(refIndex) {
      await this.$refs[refIndex][0].$swiper.slidePrev()
    },
    openSetImageModal(index, indexRef, type, key) {
      this.currentIndex = index
      this.currentIndexRef = indexRef
      this.refType = type
      this.isShowModalRefType = true
      this.currentImageKey = key
    },
    setImageIdOverview(arrayImage) {
      this.imageOverviewOnEdit = arrayImage
    },
    setImageRef(imageArray) {
      this.arrayDescriptionOnEdit[this.currentIndex][this.currentImageKey][this.currentIndexRef].image = imageArray[0]
    },
    forceUpdate() {
      this.componentKey += 1
    },
    async appendElemen(index, refIndex, key) {
      if(key === 'benefits'){
        console.log(key)
        await this.arrayDescriptionOnEdit[index][key].push({
          image: null,
          title:'',
          content:['']
        })
      }else if (key === 'pwcServices'){
        await this.arrayDescriptionOnEdit[index][key].push({
          image: null,
          title:'',
          description:'',
          content:['']
        })
      }else {
        await this.arrayDescriptionOnEdit[index][key].push({})
      }
      await this.forceUpdate()
      await this.$refs[refIndex][0].$swiper.slideTo(this.arrayDescriptionOnEdit[index][key].length, 1)
    },
    async removeElemen(index, indexService, refIndex, key) {
      this.arrayDescriptionOnEdit[index][key].splice(indexService, 1)
      await this.forceUpdate()
      await this.$refs[refIndex][0].$swiper.slideTo(indexService - 1, 1)
    },
    onChangeTitle() {
      this.productOnEdit.slug = utils.toSlug(this.productOnEdit.title)
    },
    setImageId(arrayID) {
      this.imageHeaderOnEdit = arrayID
    },
    setImagesId(arrayID) {
      this.imagesHowItWorkOnEdit = arrayID
    },
    updateProductType(val) {
      this.productEdit.type = val
    },
    updateProductCategory(val) {
      this.productEdit.category_id = val
    },
    addNewItemInItemFormDescription() {
      this.arrayDescriptionOnEdit.push({
        region_id: '',
        title: '',
        description: '',
        recomendFors: [],
        issueItFor: [],
        benefits: [],
        pwcTeams: [],
        pwcServices: [],
        relatedSource: [],
        smilarAssets: [],
      })
      this.countOnEdit += 1
    },
    addNewItemInItemFormRegionPrice() {
      this.arrayDescriptionOnEdit.push({
        title: '',
        description: '',
        region_id: '',
      })
      this.countOnEdit += 1
    },
    removeItem(index) {
      this.arrayDescriptionOnEdit.splice(index, 1)
      this.countOnEdit -= 1
    },
    confirmProduct() {
      this.$refs.editProductValidation.validate().then(success => {
        const arrImage = this.imageHeaderOnEdit.concat(this.imagesHowItWorkOnEdit)
        const arrImageID = arrImage.map(x => x.id)
        if (success) {
          this.loading = true
          const productUpdateData = {
            offering_type: 'asset',
            title: this.productOnEdit.title,
            tag_ids: this.productOnEdit.tag_ids.map(x => x.value),
            type: this.productOnEdit.type.value,
            from_price: parseInt(this.productOnEdit.from_price, 10),
            slug: this.productOnEdit.slug,
            description: [{
              region_id: this.arrayDescriptionOnEdit[0].region_id.region,
              title: this.arrayDescriptionOnEdit[0].title,
              description: this.arrayDescriptionOnEdit[0].description,
              summary: this.arrayDescriptionOnEdit[0].summary,
            }],
            metadata: {
              regions_form: this.arrayDescriptionOnEdit,
              imageOverview: this.imageOverviewOnEdit[0],
              smilarAssets: this.productOnEdit.smilarAssets,
              relatedSource: this.productOnEdit.relatedSource,
            },
            metadata2: {
              properties: this.arrayDescriptionOnEdit.map(x => ({
                region_id: x.region_id,
                title: x.title,
                description: x.description,
                summary: x.summary,
              })),
            },
            product_role_ids: this.productOnEdit.product_roles.map(x => x.value),
            product_bussiness_challenge_ids: this.productOnEdit.product_bussiness_challenges.map(x => x.value),
            category_ids: this.productOnEdit.category_ids.map(x => x.value),
            media_ids: arrImageID,
          }
          store.dispatch('product/updateProductOnEdit', { id: this.idProduct, product: productUpdateData }).then(response => {
            if (response.request.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Update product successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.productEdit = null
              this.arrayDescription = []
              this.images = []
              this.loading = false
              router.push('/asset-manager')
            }
          }).catch(e => {
            this.loading = false
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${e.message}`,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}
.select-image{
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  border: dash grey;
}

.add-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #DB536A;
  color: white;
  border-radius: 1rem;
  border-color: #DB536A;
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
}

.back-sub-element {
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background-color: #DB536A;
}
.sub-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #DB536A;
  color: white;
  border-radius: 1rem;
  border-color: #DB536A;
  cursor: pointer;
  justify-content: center;
}

.select-image-benefit {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 2px;
  border-radius: 1rem;
  border-color: #DB536A; cursor: pointer;
}
.swiper-button-next {
  color:#DB536A
}
.swiper-button-prev {
  color: #DB536A;
}
.related-source{
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
}
</style>
